.grabber {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	// min-height: 100%;
	// min-height: 100%;
	position: relative;
	overflow-y: hidden;

	@include lg {
		margin-top: 64px;
	}

	z-index: 1;

	&__spacer {
		display: flex;
		background-color: #a74bff;
		width: 100%;
		height: 300px;
	}

	&__phone {
		height: 600px;
		width: 100%;
		display: flex;
		justify-content: center;

		@include lg {
		}
	}

	&__banners {
		margin-top: 16px;
	}

	@include lg {
		display: flex;
		// flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		// height: 600px;
		// margin: 120px 0px;
		position: relative;
	}

	&__items {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100%;
		width: 100%;
		position: relative;
		overflow-x: hidden;

		@include lg {
			flex-direction: row;
			width: 100%;
		}

		@include xl {
			width: 80%;
		}
	}

	&__scroll {
		// position: absolute;
		// margin-top: 64px;
		align-self: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 16px;
		align-items: center;
		height: 96px;
		position: relative;
		width: 96px;
		cursor: pointer;

		&__image {
			width: 96px;
			height: 96px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__icon {
			position: absolute;
			z-index: 2;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		z-index: 100;

		text-align: center;

		@include lg {
			width: 90%;
			text-align: left;
		}

		&__text {
			h3 {
				@include body-title;
			}

			p {
				@include body-text;
			}
		}
		&__title {
			@include large-title;
			margin: 0 !important;
			padding: 0 !important;
			text-align: center;
			// font-size: 96px;
			font-size: 48px !important;
			// width: 500px;

			@include lg {
				font-size: 96px !important;
			}
		}

		&__subtitle {
			@include large-title;
			margin: 0 !important;
			padding: 0 !important;
			// font-size: 96px;
			font-size: 24px !important;
			font-weight: 600;
			margin-left: 16px;
			// width: 500px;
		}
	}
}
