.all-text {
	max-width: 600px;
	width: 100%;

	h5 {
		font-size: 24px;
		margin-top: 16px;
		margin-bottom: 16px;
	}
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-end {
	justify-content: flex-end;
}

.screen-height {
	min-height: 100vh !important;
}

.wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	> * {
		margin-right: 16px;
		margin-bottom: 16px;
	}
}

.split-container {
	// flex-direction: column;
	z-index: 1;
	align-items: center;
	max-width: 100%;

	&--padding {
		padding: 0px 16px;
	}

	@include xs {
		width: 100%;
	}

	@include sm {
		height: 100%;
		width: 50%;
		justify-content: center;
	}
}

// .container {
// 	display: flex;
// 	width: 100%;
// 	padding-top: 100px;
// 	z-index: 1;

// 	@include xs {
// 		margin-top: 0px;
// 		padding: 0px 16px;
// 		width: 100%;
// 	}

// 	@include lg {
// 		padding: 0px 64px;
// 		width: 100%;
// 	}

// 	@include lg {
// 		padding: 0px 128px;
// 		width: 100%;
// 	}
// }

.page-content-centered {
	display: flex;
	flex-direction: column;
	margin: auto;
	justify-content: center;
	align-items: center;
}

.page-content {
	display: flex;
	margin-top: 16px;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;

	justify-content: flex-start;

	@include lg {
		margin-top: 100px;
	}
}

.half {
	display: flex;
	flex-direction: column;
	height: 50%;
	width: 100%;
	padding: 0px 72px;

	.title {
		@include title;
	}

	.subtitle {
		@include subtitle;
	}
}

.navigation-adjusted {
	height: calc(100vh - 80px);
}

.third {
	display: flex;
	flex-direction: column;
	height: 33%;
	width: 100%;

	.title {
		@include title;
		@include xs {
			align: center;
		}
	}

	.subtitle {
		@include subtitle;
		@include xs {
			align: center;
		}
	}
}

.responsive-third {
	@include xs {
		display: none;
	}

	@include sm {
		display: flex;
	}
}

.mobile-show {
	@include xs {
		display: flex;
	}

	@include lg {
		display: none;
	}
}

.mobile-hide {
	@include xs {
		display: none;
	}
	@include lg {
		display: flex;
	}
}
