.unique-selling-points {
	display: flex;
	width: 100%;
	height: 100%;
	// background-color: $color-900;
	// border: 1px solid $color-800;
	border-radius: 8px;

	&__items {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: auto;
		// padding: 16px;
	}
}

.unique-selling-point {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80%;
	min-height: 800px;
	// margin-bottom: 24px;
	padding: 16px;
	align-self: center;

	&__spacer {
		// display: flex;
		height: 300px;
		width: 100%;
		// background-color: $color-900;
	}

	&__parent {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	h3 {
		font-size: 24px;
		font-weight: 700;
	}

	@include lg {
		text-align: left;
		justify-content: center;
		// margin-bottom: 128px;
		flex-direction: row;

		&--reverse {
			flex-direction: row-reverse;
			text-align: right;
		}
	}

	&__info-side {
		display: flex;
		height: 100%;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		text-align: center;
		padding: 48px 0px;

		// border-top: 4px solid $color-800;

		@include lg {
			width: 70%;
			text-align: left;
			padding: 64px 0px 64px 64px;
			margin-bottom: unset;
			border: unset;
		}

		&__content {
			display: flex;
			height: 100%;
			width: 100%;
			flex-direction: column;

			@include lg {
				width: 90%;
				justify-content: space-around;
				align-items: flex-end;
			}
		}

		&--reverse {
			@include lg {
				text-align: right;
				align-items: flex-end;
				padding: 64px 64px 64px 0px;
			}

			&___content {
				align-items: flex-start;
			}
		}
	}

	&__title {
		@include large-title;
		// @include medium-title;
		text-transform: uppercase;
	}

	&__description {
		@include usp_description;
		margin-top: 16px;
	}

	&__image-side {
		display: flex;
		min-height: 650px;
		width: 100%;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		position: relative;

		@include lg {
			width: 30%;
		}
	}

	&__image {
		display: flex;
		height: 650px;
		min-height: 600px;
		width: 100%;
		object-fit: cover;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}
}
