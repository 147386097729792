.checkin-carousel {
	display: flex;
	flex-direction: column;
	width: 100%;
	// min-height: 400px;
	max-width: 100%;
	position: relative;
	justify-content: center;
	// border-top: 4px solid $color-800;
	padding-top: 48px;

	&__spacer {
		display: flex;
		height: 300px;
		width: 100%;
	}

	&__content {
		align-self: center;
		max-width: 80%;
	}

	&__slide {
		display: flex;
		width: 100%;
		height: 100%;
		min-width: 240px;

		@include lg {
			justify-content: unset;
		}
	}

	@include lg {
		width: 100%;
		height: 570px;
		max-width: 100%;
		justify-content: unset;
		padding-top: 0px;
		border: unset;
	}

	&__title {
		@include medium-title;
		margin-bottom: 32px;
		text-align: center;

		@include lg {
			text-align: unset;
		}
	}

	&__container {
		display: flex;
		position: absolute;
		flex-direction: row;
		width: 100%;
		height: 570px;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;

		// allow this to overflow on the right, despite the max width of its parents
		// overflow: visible;
		scrollbar-width: none;

		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;
	}
}

.checkin-card {
	display: flex;
	min-width: 280px;
	border-radius: 16px;
	width: 280px;
	height: 500px;
	background-color: $color-800;
	margin: auto;

	@include lg {
		margin: unset;
		margin-right: 32px;
	}

	&__container {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	&__content {
		display: flex;
		flex-direction: column;
		position: absolute;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;

		&__text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: 100%;
			height: 50%;
		}
	}

	&__avatar {
		display: flex;
		position: relative;
		width: 100%;
		height: 50%;
		justify-content: flex-start;
		align-items: flex-end;
		padding: 16px;
	}

	&__avatar--image {
		display: flex;
		width: 60px;
		height: 60px;
		object-fit: cover;
		overflow: hidden;
		background-color: $color-100;
		justify-content: center;
		align-items: center;
		border-radius: 100%;

		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	width: 80px;
		// 	height: 80px;
		// 	background-color: rgba(0, 0, 0, 0.3);
		// 	border-radius: 100%;
		// }
	}

	&__title {
		@include card-title;
		z-index: 100;
	}

	&__user {
		@include subtitle;
		z-index: 100;
		display: flex;
		margin-left: 8px;
		height: 60px;
		align-items: center;
	}

	&__image {
		display: flex;
		width: 280px;
		height: 500px;
		object-fit: cover;
		overflow: hidden;
		justify-content: center;
		align-items: center;

		border-radius: 16px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 16px;
		}
	}
}

// .checkin-card {
// 	display: flex;
// 	min-width: 500px;
// 	border-radius: 16px;
// 	width: 500px;
// 	height: 250px;
// 	background-color: $color-800;
// 	margin-right: 32px;

// 	&__content {
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: center;
// 		align-items: center;
// 		width: 100%;
// 		height: 100%;
// 	}

// 	&__avatar {
// 		display: flex;
// 		position: absolute;
// 		width: 150px;
// 		height: 150px;
// 		object-fit: cover;
// 		overflow: hidden;
// 		justify-content: center;
// 		align-items: center;
// 		border-radius: 100px;
// 		z-index: 10;

// 		&::after {
// 			content: "";
// 			position: absolute;
// 			width: 100%;
// 			height: 100%;
// 			background-color: rgba(0, 0, 0, 0.3);
// 			border-radius: 100px;
// 		}
// 	}

// 	&__left {
// 		display: flex;
// 		position: relative;
// 		flex-direction: column;
// 		justify-content: space-between;
// 		align-items: flex-start;
// 		padding: 36px 16px 36px 36px;
// 		width: 50%;
// 		height: 100%;
// 		border-radius: 16px 0 0 16px;
// 		// background-color: $color-secondary;
// 		// opacity: 0.9;

// 		// ::after {
// 		// 	content: "";
// 		// 	// background-color: rgba(0, 0, 0, 0.3);
// 		// 	position: absolute;
// 		// 	width: 100%;
// 		// 	height: 100%;
// 		// 	border-radius: 16px 0 0 16px;
// 		// 	top: 0;
// 		// 	left: 0;
// 		// }
// 	}

// 	&__title {
// 		@include card-title;
// 		z-index: 100;
// 	}

// 	&__user {
// 		@include subtitle;
// 		z-index: 100;
// 	}

// 	&__right {
// 		display: flex;
// 		flex-direction: column;
// 		width: 50%;
// 		height: 100%;
// 		border-radius: 0 16px 16px 0;
// 	}

// 	&__image {
// 		display: flex;
// 		width: 250px;
// 		height: 250px;
// 		object-fit: cover;
// 		overflow: hidden;
// 		justify-content: center;
// 		align-items: center;
// 		border-radius: 0 16px 16px 0;
// 	}
// }
